import { useFindChannelQuery } from '@api/query/channelApi';
import { useFindInvoicingSettingsQuery } from '@api/query/invoicingSetting';
import { useFindPaymentMethodQuery } from '@api/query/paymentMethod';
import { useFindLocationsQuery } from "@api/query/customerApi/customerApi";
import { ACTIONS_ONSHIPMENT } from '@utils/Constants';
import { dayjsRanges, formats } from '@utils/dayjs';
import dayjs from 'dayjs';
import { styled } from 'goober';
import { uniqBy } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { DatePicker, Form, Message, Panel, SelectPicker, toaster } from 'rsuite';
import { CONFIRM_ORDER_SLOT1 } from "../../utils/Notifications";
import { Customer } from '@api/query/customerApi/types';
import { DeliverySlot } from '@api/query/deliverySlot/types';
import { useFindDeliverySlotsQuery } from '@api/query/deliverySlot/deliverySlot';
import { useIntl } from 'react-intl';

type fulfillmentType = {
  id: string;
  description: string;
  idDelivery: string;
  deliveryType: string;
  deliveryPartner: string;
  companyId: string;
}
export type AdditionalInfoProps = {
  value: AdditionalInfoFormValues;
  onChange: Dispatch<SetStateAction<AdditionalInfoFormValues>>;
  className?: string;
  mode?: string;
  customer?: Customer;
  controlsInventory?: boolean;
  setInvoicing;
  fulfillmentTypes?: [fulfillmentType]
};

export type AdditionalInfoFormValues = {
  deliveryDate: Date;
  deliverySlot: string[];
  deliverySlotId: string;
  invoicingStatus: string;
  invoicedDateTime: Date;
  invoicingSettingId: string;
  channelId: string;
  paymentMethodId: string;
  fulfillmentTypeId: string;
  locationId?: string;
  paymentMethod: {
    id: string;
    description: string;
    idERP: string;
    receiptPaymentMethodIdERP: string;
    accountIdERP: string;
    externalIds: [{
      source: string;
      value: string;
    }];
  };
  location?: {
    id: string;
    name: string;
    idERP: string;
    companyId: string;
    externalIds: [{
      source: string;
      value: string;
    }];
  };
  fulfillmentType: fulfillmentType;
  channel: {
    id: string;
    description: string;
  };
  actionOnShipment: string;
};

const FormStyled = styled(Form)`
  & .rs-form:not(.rs-form-inline),
  & .rs-form-group:not(:last-child) {
    margin-bottom: 8px !important;
  }
`;

function calcDeliverySlotOptions(deliveryDate: Date, slots?: DeliverySlot[]) {
  return uniqBy(
    (slots || [])
      // .filter(({ startDateTime }) =>
      //   dayjs(startDateTime).isSame(deliveryDate, 'day'),
      // )
      .map(({ startDateTime, endDateTime, id }) => ({
        label: `${dayjs(startDateTime).format(formats.time)} - ${dayjs(
          endDateTime,
        ).format(formats.time)}`,
        value: [startDateTime, endDateTime, id],
      })),
    'label',
  );
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  className,
  customer,
  value,
  onChange,
  setInvoicing,
  fulfillmentTypes,
  controlsInventory,
  mode,
}) => {
  const { $t } = useIntl();
  const paymentMethods = useFindPaymentMethodQuery({});
  const channels = useFindChannelQuery({});
  const locationsData = useFindLocationsQuery({});
  const invoicingSetting = useFindInvoicingSettingsQuery({ active: true });
  const deliverySlot = useFindDeliverySlotsQuery({
    startDateTime: new Date(value.deliveryDate),
    orderBy: 'startDateTime:ASC'
  });
  setInvoicing(invoicingSetting?.data?.items)
  const actionOnShipment = ACTIONS_ONSHIPMENT;

  const deliverySlotOptions = useMemo(
    () => calcDeliverySlotOptions(value.deliveryDate, deliverySlot.data?.items),
    [deliverySlot.data, value.deliveryDate],
  );
  useEffect(() => {
    if (!deliverySlot.isSuccess) return;
    if (value.deliverySlot[2]) {
      
      const foundDeliverySlot = deliverySlot.data?.items.find(
        slot => slot.id === value.deliverySlot[2],
      );
      if (foundDeliverySlot?.usedCapacity >= foundDeliverySlot?.capacity) {
        toaster.push(
          <Message
            type="warning"
            showIcon
            closable
            duration={10000}>
            {CONFIRM_ORDER_SLOT1}
          </Message>,
        );
      }
      if (!foundDeliverySlot) {
        return;
      }
      onChange(values => ({
        ...values,
        deliverySlot: [
          foundDeliverySlot.startDateTime,
          foundDeliverySlot.endDateTime,
          foundDeliverySlot.id,
        ],
      }));
    }
  }, [value.deliverySlot[2], deliverySlot]);
  useEffect(() => {
    if (value.paymentMethodId) {
      const paymentMethod = paymentMethods.data?.items.find(
        paymentMethod => paymentMethod.id === value.paymentMethodId,
      );

      onChange(
        values =>
          ({
            ...values,
            paymentMethod,
          } as any),
      );
    }
  }, [value.paymentMethodId, paymentMethods]);
  useEffect(() => {
    if (!value.locationId) {
      const location = locationsData.data?.items.find(
        location => location.isDefault === true,
      );
      value.locationId = location?.id

      onChange(
        values =>
          ({
            ...values,
            location,
          } as any),
      );
    } else {
      const location = locationsData.data?.items.find(
        location => location?.id === value.locationId,
      );
      value.locationId = location?.id

      onChange(
        values =>
          ({
            ...values,
            location,
          } as any),
      );
    }
  }, [value.locationId, locationsData]);
  
  useEffect(() => {
    if (value.fulfillmentTypeId) {
      const fulfillmentType = fulfillmentTypes.find(
        item => item.id === value.fulfillmentTypeId,
      );
      onChange(
        values =>
          ({
            ...values,
            fulfillmentType,
          } as any),
      );
    }
  }, [value.fulfillmentTypeId, fulfillmentTypes]);
  useEffect(() => {
    if (value.channelId) {
      const channel = channels.data?.items.find(
        channel => channel.id === value.channelId,
      );

      onChange(
        values =>
          ({
            ...values,
            channel,
          } as any),
      );
    }
  }, [value.channelId, channels]);

  const selectDefaultDeliverySlot = date => {
    setTimeout(
      () =>
        onChange(values => ({
          ...values,
          deliverySlot:
            calcDeliverySlotOptions(date, deliverySlot.data?.items)[0]?.value || [],
        })),
      200,
    );
  };
  return (
    <Panel
      className={`${className}`}
      header={
        <div className="flex justify-between">
          <span className="font-medium">Información adicional</span>
        </div>
      }>
      <FormStyled
        className="grid grid-cols-2 justify-items-stretch gap-x-2 "
        formValue={value}
        fluid
        onChange={onChange}>
        <Form.Group className="col-span-full">
          <Form.ControlLabel>Fecha de entrega</Form.ControlLabel>
          <Form.Control
            name="deliveryDate"
            style={{ width: '100%' }}
            disabledDate={
              date => !(mode === 'edit') && dayjs(date).isBefore(new Date(), 'day')
              // calcDeliverySlotOptions(date, deliverySlot.data).length === 0
            }
            editable={false}
            appearance="subtle"
            format="E dd 'de' MMMM yyyy"
            oneTap
            cleanable={false}
            accepter={DatePicker}
            ranges={dayjsRanges(['today', 'tomorrow']).map(
              ({ label, value }) => ({
                label,
                value: value[1],
              }),
            )}
            onChangeCalendarDate={selectDefaultDeliverySlot}
          />
        </Form.Group>
        {deliverySlot.isSuccess && (
          <Form.Group className="col-span-full">
            <Form.ControlLabel>Franja horaria</Form.ControlLabel>
            <Form.Control
              name="deliverySlot"
              accepter={SelectPicker}
              appearance="subtle"
              style={{ width: '100%' }}
              cleanable={false}
              searchable={false}
              data={deliverySlotOptions}
            />
          </Form.Group>
        )}
        {locationsData.isSuccess && controlsInventory && (
          <Form.Group className="col-span-full">
            <Form.ControlLabel>{$t({ id: 'fulfilmentLocation' })}</Form.ControlLabel>
            <Form.Control
              name="locationId"
              style={{ width: '100%' }}
              appearance="subtle"
              cleanable={false}
              accepter={SelectPicker}
              searchable={false}
              disabled={mode === 'edit'}
              data={locationsData.data?.items.map(
                ({
                  companyId,
                  id,
                  idERP,
                  isDefault,
                  name,
                }) => ({
                  label: name,
                  value: id,
                  idERP,
                  isDefault,
                  companyId,
                }),
              )}
            />
          </Form.Group>
        )}
        {fulfillmentTypes && (
          <Form.Group className="col-span-full">
            <Form.ControlLabel>Tipo Logistica</Form.ControlLabel>
            <Form.Control
              name="fulfillmentTypeId"
              style={{ width: '100%' }}
              appearance="subtle"
              cleanable={false}
              accepter={SelectPicker}
              searchable={false}
              data={fulfillmentTypes?.map(
                ({
                  id,
                  description,
                  idDelivery,
                  deliveryType,
                  deliveryPartner,
                  companyId,
                }) => ({
                  label: description,
                  value: id,
                  idDelivery,
                  deliveryType,
                  deliveryPartner,
                  companyId,
                }),
              )}
            />
          </Form.Group>
        )}
        {invoicingSetting.isSuccess && (
          <Form.Group>
            <Form.ControlLabel>Numeración de facturación</Form.ControlLabel>
            <Form.Control
              name="invoicingSettingId"
              style={{ width: '100%' }}
              appearance="subtle"
              accepter={SelectPicker}
              cleanable={false}
              searchable={false}
              data={invoicingSetting.data?.items.map(({ description, id }) => ({
                label: description,
                value: id,
              }))}
            />
          </Form.Group>
        )}
        {paymentMethods.isSuccess && (
          <Form.Group>
            <Form.ControlLabel>Método de pago</Form.ControlLabel>
            <Form.Control
              name="paymentMethodId"
              style={{ width: '100%' }}
              appearance="subtle"
              cleanable={false}
              accepter={SelectPicker}
              searchable={false}
              data={paymentMethods.data?.items.map(
                ({
                  description,
                  id,
                  idERP,
                  accountIdERP,
                  receiptPaymentMethodIdERP,
                }) => ({
                  label: description,
                  value: id,
                  idERP,
                  accountIdERP,
                  receiptPaymentMethodIdERP,
                }),
              )}
            />
          </Form.Group>
        )}
        {channels.isSuccess && (
          <Form.Group>
            <Form.ControlLabel>Canal</Form.ControlLabel>
            <Form.Control
              name="channelId"
              style={{ width: '100%' }}
              appearance="subtle"
              accepter={SelectPicker}
              cleanable={false}
              searchable={false}
              data={channels.data?.items.map(({ description, id }) => ({
                label: description,
                value: id,
              }))}
            />
          </Form.Group>
        )}
        <Form.Group>
          <Form.ControlLabel>Acción al despachar</Form.ControlLabel>
          <Form.Control
            name="actionOnShipment"
            style={{ width: '100%' }}
            appearance="subtle"
            accepter={SelectPicker}
            cleanable={false}
            searchable={false}
            data={actionOnShipment}
          />
        </Form.Group>
      </FormStyled>
    </Panel>
  );
};

export default AdditionalInfo;
