import { roundResult } from '@utils/configERP';
import { formatCurrencySpanishCO } from '@utils/DataFormat';
import React from 'react'
import { FormattedMessage } from "react-intl";

export function GeneralInformation({ product }) {
  return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left text-gray-500">
      <tbody>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="name" />
          </th>
          <td className="px-6 py-2">
            {product.name}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="sku" />
          </th>
          <td className="px-6 py-2">
            {product?.sku}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="price-before-taxes" />
          </th>
          <td className="px-6 py-2">
          {formatCurrencySpanishCO(
            roundResult(product.priceBeforeTax)
          )}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="price" />
          </th>
          <td className="px-6 py-2">
            {formatCurrencySpanishCO(
              roundResult(product.priceAfterTax)
            )}
          </td>
        </tr>
        <tr className="border-b border-gray-200">
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="taxes" />
          </th>
          <td className="px-6 py-2">
            {`${product?.tax}%`}
          </td>
        </tr>
        <tr>
          <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap w-1/3 bg-gray-50">
            <FormattedMessage id="state" />
          </th>
          <td className="px-6 py-2">
            {product?.active ? "Activo" : "Inactivo"}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}