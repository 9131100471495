import { roundResult } from '@utils/configERP';
import { formatCurrencySpanishCO } from '@utils/DataFormat';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function ChilItemsInformation({ items }) {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead>
          <tr className="border-b border-gray-200">
            <th className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap w-4/5 bg-gray-50">
              <FormattedMessage id="name" />
            </th>
            <th className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap w-1/4 bg-gray-50">
              <FormattedMessage id="sku" />
            </th>
            <th className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap w-1/6 bg-gray-50">
              <FormattedMessage id="quantity" />
            </th>
            <th className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap w-1/5 bg-gray-50">
              <FormattedMessage id="price" />
            </th>
            <th className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap w-1/6 bg-gray-50">
              <FormattedMessage id="discount" />
            </th>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="px-6 py-4">{item.name}</td>
              <td className="px-6 py-4">{item.sku}</td>
              <td className="px-6 py-4">{item.quantity}</td>
              <td className="px-6 py-4">
                {formatCurrencySpanishCO(roundResult(item.priceAfterTax || 0))}
              </td>
              <td className="px-6 py-4">
                {`${item.discount}%`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
