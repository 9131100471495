import React from "react"
import { FormattedMessage } from "react-intl";
import { Drawer, Modal } from "rsuite";

import { TabsComponent } from '../../../components/Tabs'
import { TabContent } from '../../../components/Tabs/TabContent'
import { GeneralInformation } from "./GeneralInformation";
import { ChilItemsInformation } from "./ChilItemsInformation";
import { Product } from "@api/query/productApi/types";


interface Props {
	product: Product;
	oncloseModal: () => void;
	isOpenDetailModal: boolean;
}
const getTabs = (product): any[] => {
    const tabs = [
      {
        id: 0,
        label: <FormattedMessage id="general-information" />
      },
    ];

    if (product?.isKit) {
      tabs.push({
        id: 1,
        label: <FormattedMessage id="child-items" />
      });
    }

    return tabs;
  };

export function ModalProductDetail({ isOpenDetailModal, oncloseModal, product }: Props) {
	return (
		<div>
			<Modal
				size="md"
				open={isOpenDetailModal}
				onClose={() => oncloseModal()}
				autoFocus
				keyboard
			>
				<Modal.Header>
					<Drawer.Title className="font-medium  text-xl">
						{product.name}
					</Drawer.Title>
				</Modal.Header>
				<Modal.Body  >
					<TabsComponent tabs={getTabs(product)}>
						<TabContent id={0}>
							<GeneralInformation product={product}/>
						</TabContent>
            {product?.isKit && (
              <TabContent id={1}>
                <ChilItemsInformation items={product?.childItems}/>
              </TabContent>
            )}
					</TabsComponent>
					<div className="bg-red container">
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
};