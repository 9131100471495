import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ordoxBaseQuery } from './baseQuery';


export type PaymentMethod = {
  id: string;
  description: string;
  idERP: string;
  createdAt: string;
  updateAt: string;
  accountIdERP: string;
  receiptPaymentMethodIdERP: string;
  companyId: string;
  externalIds: [{
    source: string;
    value: string;
  }];
};

type FindPaymentMethodRequest = {};
type FindPaymentMethodResponse = {
  total: number;
  items: PaymentMethod[];
};

export const paymentMethodApi = createApi({
  reducerPath: 'paymentMethod-api',
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    findPaymentMethod: builder.query<FindPaymentMethodResponse, FindPaymentMethodRequest>(
      {
        query: (filters) => ({
          url: `payment-methods`,
          method: 'GET'
        }),
      },
    ),
  }),
});

export const { useFindPaymentMethodQuery } = paymentMethodApi;

export default paymentMethodApi;
