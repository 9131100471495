import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker, Form, Panel, SelectPicker } from 'rsuite';
import { styled } from 'goober';
import { dayjsRanges } from '@utils/dayjs';
import dayjs from 'dayjs';
import React from 'react';
import { invoicingStatusOptions } from '@utils/Constants';

const FormStyled = styled(Form)`
  & .rs-form:not(.rs-form-inline),
  & .rs-form-group:not(:last-child) {
    margin-bottom: 8px !important;
  }
`;

export type InvoiceFieldProps = {
  className?: string;
  invoiceId: string;
  invoiceIdERP: string;
  mode: string;
  invoicedDateTime: Date;
  invoicingStatus: string;
  value: InvoiceFieldValues;
  onchange: React.Dispatch<React.SetStateAction<InvoiceFieldValues>>;
  onChange?(
    ev: React.ChangeEvent<HTMLInputElement>,
    name: 'invoiceId' | 'invoiceIdERP' | 'invoicingStatus' | 'invoicedDateTime',
    value: string | Date | null,
  ): void;
};

export type InvoiceFieldValues = {
  deliverySlotId: string;
  invoicingStatus: string;
  invoicedDateTime: Date;
};

const InvoiceField: React.FC<InvoiceFieldProps> = ({
  className,
  invoiceId,
  invoiceIdERP,
  invoicingStatus,
  invoicedDateTime,
  mode,
  value,
  onChange,
  onchange,
}) => {
  const { $t } = useIntl();
  const handleDeliveryDateChange = (newDate: Date | null) => {
    const dateValue = newDate ?? new Date();

    if (onChange) {
      onChange(null, 'invoicedDateTime', dateValue);
    }
    onchange((prevValue) => ({
      ...prevValue,
      invoicedDateTime: dateValue,
    }));
  };

  return (
    <Panel
      className={className}
      header={
        <div className="flex justify-between">
          <span className="font-medium">
            <FormattedMessage id="invoiceInfo" />
          </span>
        </div>
      }
    >
      <FormStyled
        className="grid grid-cols-1 sm:grid-cols-2 gap-4"
        formValue={value}
        fluid
        onChange={onchange}
      >
        <div className="space-y-4">
          <Form.Group>
            <Form.ControlLabel>{$t({ id: 'facturaId' })}</Form.ControlLabel>
            <Form.Control
              name="invoiceId"
              value={invoiceId}
              maxLength={15}
              onChange={(value, ev) => onChange?.(ev, 'invoiceId', value)}
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>{$t({ id: 'facturaIdERP' })}</Form.ControlLabel>
            <Form.Control
              name="invoiceIdERP"
              value={invoiceIdERP}
              maxLength={15}
              onChange={(value, ev) => onChange?.(ev, 'invoiceIdERP', value)}
            />
          </Form.Group>
        </div>
        <div className="space-y-4">
          <Form.Group>
            <Form.ControlLabel>Fecha de facturación</Form.ControlLabel>
            <Form.Control
              name="invoicedDateTime"
              style={{ width: '100%' }}
              disabledDate={(date) => !(mode === 'edit') && dayjs(date).isBefore(new Date(), 'day')}
              editable={false}
              appearance="subtle"
              format="E dd 'de' MMMM yyyy"
              oneTap
              cleanable={false}
              accepter={DatePicker}
              value={invoicedDateTime || null}
              onChange={handleDeliveryDateChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.ControlLabel>Estado facturación</Form.ControlLabel>
            <Form.Control
              name="invoicingStatus"
              style={{ width: '100%' }}
              accepter={SelectPicker}
              data={invoicingStatusOptions}
              value={value.invoicingStatus || invoicingStatus}
              onChange={(value) => onChange?.(null, 'invoicingStatus', value)}
              placeholder="Selecciona el estado"
              searchable={false}
              cleanable={false}
            />
          </Form.Group>
        </div>
      </FormStyled>
    </Panel>
  );
};

export default InvoiceField;